import { Box, Button, Container, Grid, Stack, Input, Typography, useColorScheme } from "@mui/joy";

import React, { useEffect, useState } from "react";
import Meta from "./Meta";
import { useMobileMode } from "./Responsive";
import details from "assets/Details";
import { Link } from "react-router-dom";
import { IoIosReturnLeft } from "react-icons/io";
// import { FaMinus, FaPlus } from "react-icons/fa";
// import Item from "./capabilities/Item";
// import { SkillButton } from "./capabilities/SkillButton";
// import { SiPowerbi, SiReact } from "react-icons/si";
import { animated, useSpringRef, useTransition } from "@react-spring/web";
import ProgressiveImage from "./ProgressiveImage";
import ContactUS from "../assets/icons/bbbbbbbbbbbbbbbbbbbbbbbbbb.png";
import { GoLocation } from "react-icons/go";
import { BsMailbox, BsPhone } from "react-icons/bs";
// import { MdEmail } from "react-icons/md";
import Credits from "./sections/Credits";

export default function ContactUs() {
  function ThemeAwareIllustration() {
    const { colorScheme } = useColorScheme();
    const [loaded, setLoaded] = useState(false);

    const transRef = useSpringRef();

    const transitions = useTransition(colorScheme, {
      ref: transRef,
      keys: null,
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0, filter: "blur(10px)", position: "absolute" },
    });

    useEffect(() => {
      if (loaded) {
        transRef.start();
      }
    }, );
    // [colorScheme, loaded]
    const imgSx = {
      position: "relative",
      marginTop: "-5rem",
      width: "100vw",
      // WebkitMaskImage: "linear-gradient(to left,black 10%,transparent 80%)",
      // maskImage: "linear-gradient(to left,black 10%,transparent 99.99%)",
      // filter: "grayscale(1)",
    } as React.CSSProperties;

    return (
      <Stack
        sx={{
          position: "absolute",
          // right: "min(0rem, calc(100vw - 100rem))",
          width: "100vw",
          height: "min(100%, 27rem)",
          overflow: "hidden",
          paddingTop: "var(--nav-safe-area-inset-top)",
          "&:after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            // backdropFilter: "blur(20px)",
            // mask: "linear-gradient(to left, transparent, black 60%)",
          },
        }}
      >
        <div
          id="trio"
          style={{
            zIndex: 999,
            position: "fixed",
            width: "15px",
            height: "100vh",
            background:
              "linear-gradient(to bottom, #F7525B 10%, #1C2B4B 45%, #21A2B2 70%, #F7525B 100%)",
          }}
        ></div>
        {transitions((style, item) => {
          switch (item) {
            case "light":
              return (
                <animated.div style={style}>
                  <ProgressiveImage
                    src={ContactUS}
                    placeholder={ContactUS}
                    alt="Brutalist building by Arthur Swiffen"
                    onLoad={() => setLoaded(true)}
                    style={imgSx}
                  />
                </animated.div>
              );
            case "dark":
              return (
                <animated.div style={style}>
                  <ProgressiveImage
                    src={ContactUS}
                    placeholder={ContactUS}
                    alt="Red lamps in Subway in Hamburg by Travel with Lenses"
                    onLoad={() => setLoaded(true)}
                    style={imgSx}
                  />
                </animated.div>
              );
            default:
              return null;
          }
        })}
      </Stack>
    );
  }

  const mobile = useMobileMode();
  return (
    <Stack>
    <Stack
      width="100%"
      position="relative"
      overflow="hidden"
      sx={{
        paddingLeft: "var(--nav-safe-area-inset-left)",
        paddingBottom: "var(--nav-safe-area-inset-bottom)",
      }}
    >
      <Meta title={`Contact ${details.name.first}`} />
      <ThemeAwareIllustration />
      <Stack padding={mobile ? "1rem" : "5rem"} gap={2} alignItems="start">
        <Stack marginBottom="min(10vw, 3rem)" marginTop={mobile ? "10vw" : 5}>
          <Typography
            level="display1"
            fontSize="clamp(3rem, 15vw, 10rem)"
            fontWeight={200}
            lineHeight={1}
            sx={{
              position: "relative",
              marginLeft: "0.25em",
              marginBottom: "0.05em",
              color: "white"
            }}
          >
            <Typography
              level="h2"
              sx={{
                position: "absolute",
                left: "-0.6em",
                bottom: "1.7em",
                transform: "translateX(-50%) rotate(-90deg)",
                fontSize: "clamp(0.5rem, 3vw, 2rem)",
              }}
            >
              {/* Reach Out */}
            </Typography>
            {/* We'd love to hear from you ! */}
          </Typography>
          <Typography
            level="display1"
            fontSize="clamp(3rem, 15vw, 10rem)"
            fontWeight={300}
            lineHeight={0.6}
            zIndex={1}
            fontFamily={'"Righteous", sans-serif'}
          >
            {/* Us */}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          gap={1}
          sx={
            mobile
              ? {
                  width: "100%",
                  "& > *:first-child": {
                    flex: 1,
                  },
                }
              : {}
          }
        >
          <Button
            size="lg"
            component={Link}
            to="/"
            variant="outlined"
            color="neutral"
            endDecorator={<IoIosReturnLeft size="1.3em" />}
            sx={(theme) => ({
              marginTop: "11.5em",
              transition: "all ease .2s",
              position: "relative",
              borderRadius: "20px",
              boxShadow: "1px 1px grey",
              width: "fit-content",
              flexShrink: 0,
              padding: "1 2",
              background: theme.palette.background.body,

             '&:hover': {
                // background: theme.palette.text.primary,
                color: theme.palette.background.level1,
                borderColor: theme.palette.text.primary,
                '& > span > svg': {
                  transform: 'translate(.6rem, .3rem) scale(1.2)',
                  filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.tertiary}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.secondary})`,
                },
              },
              '&:active': {
                transform: 'scale(.98)',
                '& > span > svg': {
                  transform: 'translate(.6rem, .3rem) scale(1.1)',
                  filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1})`,
                },
              },
              '& > span > svg': {
                transition: 'all ease .2s',
              },
            })}
          >
            Back to homepage
          </Button>
        </Stack>
      </Stack>
      {/* <Grid container gap={2} ml={9.8} mr={10} mt={-4} flexDirection="column"></Grid> */}
      <Box sx={{ color: "white", marginTop: "2%" }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid xs={12} md={6}>
              <Typography level="h4" sx={{ mb: 2 }}>
                Visit our office
              </Typography>
              <Typography level="body1" sx={{ mb: 1 }}>
                <GoLocation /> {"       "} Candor Tech space Sector -135 Genpact India Pvt LTD
                <br />
                &nbsp;&nbsp;&nbsp;&nbsp; Noida - Uttar Pradesh 201304
              </Typography>
              <Typography level="body1" sx={{ mb: 1 }}>
                <BsPhone /> +91-9999356344
              </Typography>
              <Typography level="body1">
                <BsMailbox />
                &nbsp;&nbsp;rag_team@genpact.com
              </Typography>
            </Grid>

            <Grid xs={12} md={6}>
              <Input placeholder="Name" sx={{ mb: 2 }} />
              <Input placeholder="Phone Number" sx={{ mb: 2 }} />
              <Input placeholder="Email" sx={{ mb: 2 }} />
              <Input placeholder="Message" sx={{ mb: 2 }} />
              <Button variant="solid" type="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
      </Stack>
      <Credits/>
    </Stack>
  );
}
