/* eslint-disable react/jsx-props-no-spreading */
import {
  Avatar,
  IconButton,
  IconButtonProps,
  Stack,
  Tab,
  Tooltip,
  Typography,
  useColorScheme,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { VscTerminalPowershell } from "react-icons/vsc";
import {
  FaCode,
  FaDatabase,
  FaPowerOff,
  FaPython,
  FaReact,
  FaRegFileExcel,
} from "react-icons/fa";
import { RiUserLine } from "react-icons/ri";
import mockMessages from "utils/Messages";
import { Parallax } from "react-scroll-parallax";
import details from "assets/Details";
import { Default, Mobile, useLandScapeMode, useMobileMode } from "../Responsive";
import LinkCarousel from "./contact/LinkCarousel";
import { FiBarChart, FiPower } from "react-icons/fi";
import CarlsbergIcon from "../../assets/icons/Carlsberg.jfif";
import RichFoodIcon from "../../assets/icons/RichFood.jfif";
import MondelezIcon from "../../assets/icons/mdlz.jpg";
import HaleonIcon from "../../assets/icons/Healeon.jfif";
import MarsIcon from "../../assets/icons/mars.jfif";
import PladisIcon from "../../assets/icons/pladis.jfif";
import TropicanaIcon from "../../assets/icons/tropicana.png";

export const categories = ["experience", "education"] as const;

export type Category = (typeof categories)[number];

export interface Tab {
  name: string;
  icon: JSX.Element;
}

export function FakeButton({
  children,
  tooltipIndex,
  setTooltipIndex,
  ...props
}: {
  children: React.ReactNode;
  tooltipIndex: number;
  setTooltipIndex: React.Dispatch<React.SetStateAction<number>>;
} & IconButtonProps) {
  const { sx, ...rest } = props;

  return (
    <Tooltip
      title={mockMessages[tooltipIndex]}
      variant="plain"
      onClose={() => {
        if (tooltipIndex < mockMessages.length - 1) {
          setTooltipIndex(tooltipIndex + 1);
        }
      }}
    >
      <IconButton
        {...rest}
        sx={(theme) => ({
          ...((typeof sx === "function" ? sx(theme) : sx) as object),
          transition: "all ease .2s",
        })}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
}

export default function Terminal() {
  const [tabs] = useState<Tab[]>([
    {
      name: `pwsh in ${details.name.nickname.toLowerCase()}`,
      icon: <VscTerminalPowershell />,
    },
  ]);
  const [displayed, setDisplayed] = useState<Category>(categories[0]);
  const [selected, setSelected] = useState<Category>(categories[0]);
  const [loadingTime, setLoadingTime] = useState<number>();
  const [tooltipIndex, setTooltipIndex] = useState(0);
  const [playing, setPlaying] = useState(true);

  const mobile = useMobileMode();
  const { colorScheme } = useColorScheme();
  const landscape = useLandScapeMode();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dark = colorScheme === "dark";

  useEffect(() => {
    setLoadingTime(Math.floor(Math.random() * 300));
  }, []);

  useEffect(() => {
    if (playing) {
      const interval = setInterval(() => {
        setDisplayed((prev) => {
          const nextIndex = (categories.indexOf(prev) + 1) % categories.length;
          return categories[nextIndex];
        });
      }, 4000);
      return () => {
        clearInterval(interval);
      };
    }
    return undefined;
  }, [playing, selected]);

  useEffect(() => {
    if (mobile) {
      setPlaying(false);
    }
  }, [mobile]);

  return (
    <Stack
      gap={3}
      alignItems={mobile ? "center" : "center"}
      width={mobile || !landscape ? "93%" : "97%"}
      sx={(theme) => ({
        position: "relative",
        "&::before": mobile
          ? {}
          : {
              content: '""',
              position: "absolute",
              top: "0",
              left: "-50px",
              height: "100%",
              // width: "2px",
              background: `linear-gradient(to bottom, ${theme.palette.primary[500]}, ${theme.palette.info[500]})`,
            },
      })}
    >
      <Mobile>
        <Avatar
          color="primary"
          sx={(theme) => ({
            position: "relative",
            border: "none",
            outline: `2px solid ${theme.palette.primary[500]}`,
            boxShadow: `0 0 40px 5px rgba(${theme.palette.primary.mainChannel} / 0.4)`,
            overflow: "visible",
            marginTop: "3rem",
            "&::before": {
              content: '""',
              position: "absolute",
              top: "-5rem",
              height: "5rem",
              width: "2px",
              background: `linear-gradient(to bottom, transparent, ${theme.palette.primary[500]})`,
            },
          })}
        >
          <RiUserLine />
        </Avatar>
      </Mobile>
      <Typography
        level="h2"
        sx={{ position: "relative", textAlign: mobile ? "center" : "left" }}
        id="profile"
      >
        <Default>
          <Avatar
            color="primary"
            sx={(theme) => ({
              position: "absolute",
              left: "-49px",
              top: "0",
              transform: "translateX(-50%)",
              border: "none",
              outline: `2px solid ${theme.palette.primary[500]}`,
              boxShadow: `0 0 40px 5px rgba(${theme.palette.primary.mainChannel} / 0.4)`,
            })}
          >
            <RiUserLine />
          </Avatar>
        </Default>
        Our{" "}
        <Typography color="primary" alignItems="center" fontWeight="xl">
          Businesses
        </Typography>
      </Typography>
      {/* <Parallax
        opacity={[0, 1]}
        disabled={mobile}
        easing="easeOutBack"
        style={{ width: "100%" }}
      > */}
        <LinkCarousel
          repeat={3}
          links={[
            {
              url: "https://www.microsoft.com/en-us/power-platform/products/power-bi",
              icon: (
                <img src={CarlsbergIcon} style={{ width: 80, height: 50 }} />
              ),
              title: "Carlsberg",
              color: "#ea4335",
            },
            {
              url: "https://www.tableau.com/",
              icon: (
                <img src={RichFoodIcon} style={{ width: 80, height: 50 }} />
              ),
              title: "Rich Food",
              color: "#4285f4",
            },
            {
              url: "https://www.microsoft.com/en-in/microsoft-365/excel",
              icon: (
                <img src={MondelezIcon} style={{ width: 80, height: 50 }} />
              ),
              title: "Mondelez",
              color: "#0a66c2",
            },
            {
              url: "https://learn.microsoft.com/en-us/office/vba/library-reference/concepts/getting-started-with-vba-in-office",
              icon: <img src={HaleonIcon} style={{ width: 80, height: 30 }} />,
              title: "Haleon",
              color: "#0a66c2",
            },
            {
              url: "https://www.microsoft.com/en-us/power-platform/products/power-apps",
              icon: <img src={MarsIcon} style={{ width: 70, height: 35 }} />,
              title: "Mars",
              color: "#0a66c2",
            },
            {
              url: "https://make.powerautomate.com/",
              icon: <img src={PladisIcon} style={{ width: 80, height: 50 }} />,
              title: "Pladis",
              color: "#0a66c2",
            },
            {
              url: "https://www.python.org/",
              icon: (
                <img src={TropicanaIcon} style={{ width: 80, height: 50 }} />
              ),
              title: "Tropicana",
              color: "#0a66c2",
            },
          ]}
        />
        {/* </Parallax> */}
    </Stack>
  );
}
