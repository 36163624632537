import {
  Button,
  Stack,
  Typography,
  Box,
  Select,
  FormControl,
  Input,
  Option,
} from "@mui/joy";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";

import React, { useState, useEffect } from "react";
import { useColorScheme } from "@mui/joy";
import { animated, useSpringRef, useTransition } from "@react-spring/web";
import Meta from "./Meta";
import { useMobileMode } from "./Responsive";
import details from "assets/Details";
import { Link } from "react-router-dom";
import { IoIosReturnLeft } from "react-icons/io";
import Credits from "./sections/Credits";
import ProgressiveImage from "./ProgressiveImage";
import ReqImage from "../assets/icons/home-banner-new.jpg";

type formState = {
  email: string;
  accountName: string;
  projectName: string;
  tower: string;
  process: string;
  currentHeadCount: number | undefined;
  currentLocation: string;
  requirementCategory: string;
  expectedOpportunity: string;
  cost: string;
};

export default function RequestRequirements() {
  const SERVICE_ID: string = "service_lbfup5p";
  const TEMPLATE_ID: string = "template_cjun8rm";
  const PUBLIC_KEY: string = "PbHn1ZjEm0wbHWa49";

  const [formState, setFormState] = useState<formState>({
    email: "",
    accountName: "",
    projectName: "",
    tower: "",
    process: "",
    currentHeadCount: undefined,
    currentLocation: "",
    requirementCategory: "",
    expectedOpportunity: "",
    cost: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const { name, value } = e.target;
    setFormState({
      ...formState,
      [name as string]: value as string,
    });
  };

  const handleSelectChange = (event: any, newValue: string | null) => {
    setFormState({
      ...formState,
      requirementCategory: newValue || "",
    });
  };

  useEffect(() => {
    let cost = "";
    switch (formState.requirementCategory) {
      case "Low":
        cost = "1000";
        break;
      case "Medium":
        cost = "5000";
        break;
      case "High":
        cost = "10000";
        break;
      default:
        cost = "";
    }
    setFormState((prevState) => ({
      ...prevState,
      cost: cost,
    }));
  }, [formState.requirementCategory]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formState);
    try {
      await emailjs.send(
        SERVICE_ID,
        TEMPLATE_ID,
        {...formState, to_name: 'RAG Team', },
        {
          publicKey: PUBLIC_KEY,
        }
      );
      console.log("SUCCESS!");
    } catch (err) {
      if (err instanceof EmailJSResponseStatus) {
        console.log("EMAILJS FAILED...", err);
        return;
      }

      console.log("ERROR", err);
    }
  };
  function ThemeAwareIllustration() {
    const { colorScheme } = useColorScheme();
    const [loaded, setLoaded] = useState(false);

    const transRef = useSpringRef();

    const transitions = useTransition(colorScheme, {
      ref: transRef,
      keys: null,
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0, filter: "blur(10px)", position: "absolute" },
    });

    useEffect(() => {
      if (loaded) {
        transRef.start();
      }
    }, );
    // [colorScheme, loaded]
    const imgSx = {
      position: "relative",
      marginTop: "-5rem",
      width: "100vw",
      // WebkitMaskImage: "linear-gradient(to left,black 10%,transparent 80%)",
      // maskImage: "linear-gradient(to left,black 10%,transparent 99.99%)",
      // filter: "grayscale(1)",
    } as React.CSSProperties;

    return (
      <Stack
        sx={{
          position: "absolute",
          // right: "min(0rem, calc(100vw - 100rem))",
          width: "100vw",
          height: "min(100%, 27rem)",
          overflow: "hidden",
          paddingTop: "var(--nav-safe-area-inset-top)",
          "&:after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            // backdropFilter: "blur(20px)",
            // mask: "linear-gradient(to left, transparent, black 60%)",
          },
        }}
      >
        {transitions((style, item) => {
          switch (item) {
            case "light":
              return (
                <animated.div style={style}>
                  <ProgressiveImage
                    src={ReqImage}
                    placeholder={ReqImage}
                    alt="Brutalist building by Arthur Swiffen"
                    onLoad={() => setLoaded(true)}
                    style={imgSx}
                  />
                </animated.div>
              );
            default:
              return null;
          }
        })}
      </Stack>
    );
  }
  const mobile = useMobileMode();
  return (
    <Stack>
      <div
          id="trio"
          style={{
            zIndex: 999,
            position: "fixed",
            width: "15px",
            height: "100vh",
            background:
              "linear-gradient(to bottom, #F7525B 10%, #1C2B4B 45%, #21A2B2 70%, #F7525B 100%)",
          }}
        ></div>
    <Stack
      width="100%"
      position="relative"
      overflow="hidden"
      sx={{
        paddingLeft: "var(--nav-safe-area-inset-left)",
        paddingBottom: "var(--nav-safe-area-inset-bottom)",
      }}
    >
      <Meta title={`Request - ${details.name.first}`} />
      <ThemeAwareIllustration />
      <Stack padding={mobile ? "1rem" : "5rem"} gap={2} alignItems="start" >
        <Stack marginBottom="min(10vw, 3rem)" marginTop={mobile ? "10vw" : 0} sx={{marginLeft: "-50px"}}>
          <Typography
            level="display1"
            fontSize="clamp(3rem, 8vw, 5rem)"
            fontWeight={20}
            lineHeight={1}
            sx={{ position: "relative", marginLeft: ".25em", marginBottom: ".05em" }}
          >
           
            Share Your
          </Typography>
          <Typography
            level="display1"
            fontSize="clamp(3rem, 9vw, 5rem)"
            fontWeight={80}
            lineHeight={0.6}
            zIndex={1}
            fontFamily={'"Righteous", sans-serif'}
          >
            Requirements
          </Typography>
        </Stack>
        <Stack
          direction="row"
          gap={1}
          sx={
            mobile
              ? {
                  width: "100%",
                  "& > *:first-child": {
                    flex: 1,
                  },
                }
              : {}
          }
        >
          <Button
            size="lg"
            component={Link}
            to="/"
            variant="outlined"
            color="neutral"
            endDecorator={<IoIosReturnLeft size="1.3em" />}
            sx={(theme) => ({
              marginTop: "6.5em",
              transition: "all ease .2s",
              position: "relative",
              borderRadius: "20px",
              boxShadow: "1px 1px grey",
              width: "fit-content",
              flexShrink: 0,
              padding: "1 2",
              left: "-30px",
              background: theme.palette.background.body,

              '&:hover': {
                // background: theme.palette.text.primary,
                color: theme.palette.background.level1,
                borderColor: theme.palette.text.primary,
                '& > span > svg': {
                  transform: 'translate(.6rem, .3rem) scale(1.2)',
                  filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.tertiary}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.secondary})`,
                },
              },
              '&:active': {
                transform: 'scale(.98)',
                '& > span > svg': {
                  transform: 'translate(.6rem, .3rem) scale(1.1)',
                  filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1})`,
                },
              },
              '& > span > svg': {
                transition: 'all ease .2s',
              },
            })}
          >
            Back to homepage
          </Button>
        </Stack>

        <Box className="App">
          <Typography level="h6">Please fill out this form</Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: "flex", alignItems: "center", marginTop: "20px", flexWrap: "wrap" }}
            gap={2}
          >
            <Input
              placeholder="Email id"
              type="email"
              name="email"
              value={formState.email}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Account Name"
              type="text"
              name="accountName"
              value={formState.accountName}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Project Name"
              type="text"
              name="projectName"
              value={formState.projectName}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Tower"
              type="text"
              name="tower"
              value={formState.tower}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Process"
              type="text"
              name="process"
              value={formState.process}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Current Head Count"
              type="number"
              name="currentHeadCount"
              value={formState.currentHeadCount}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Current Location"
              type="text"
              name="currentLocation"
              value={formState.currentLocation}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <FormControl sx={{ marginBottom: "20px", width: "300px" }}>
              <Select
                id="requirement-category-label"
                value={formState.requirementCategory}
                onChange={handleSelectChange}
              >
                <Option value="">
                  <em>Select a Requirement Category</em>
                </Option>
                <Option value="Low">Low</Option>
                <Option value="Medium">Medium</Option>
                <Option value="High">High</Option>
              </Select>
            </FormControl>
            <Input
              placeholder="Expected Opportunity"
              type="date"
              name="expectedOpportunity"
              value={formState.expectedOpportunity}
              onChange={handleInputChange}
              required
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Input
              placeholder="Cost"
              type="text"
              name="cost"
              value={formState.cost}
              sx={{ marginBottom: "20px", width: "300px" }}
            />
            <Button type="submit" variant="outlined" color="primary">
              Submit
            </Button>
          </Box>
        </Box>
      </Stack>
      </Stack>
      <Credits/>
    </Stack>
  );
}
