import React from "react";
import { Box, Typography, Button, List, ListItem, Grid } from "@mui/joy";
import { Link } from "react-router-dom";
// import { useMobileMode } from "components/Responsive";
import { GoLocation } from "react-icons/go";
import { BsMailbox, BsPhone } from "react-icons/bs";
import { useLandScapeMode, useMobileMode } from "components/Responsive";

const Footer = () => {
  const mobile = useMobileMode();
  const landscape = useLandScapeMode();
  const capabilitiesList = [
    "Analytics",
    "Automation",
    "Business Intelligence",
    "Data Automation",
    "Data Transformation",
    "Power App Development",
    "Web App Development",
  ];

  const clients = [
    "Carlsberg",
    "Mars",
    "Mondelez",
    "Unilever",
    "Tropicana",
    "Haleon",
    "Pladis",
    "Rich Food",
  ];
  const leftMargin = 0;
  const listItemColor = "white";

  return (
    <Box
      component="footer"
      sx={{ backgroundColor: "#1C2B4B", color: "#fff", p: 4, ml: leftMargin }}
    >
      <Grid container>
        <Grid
          xs={12}
          sx={mobile || !landscape ? { ml: "1%" } : {ml:"4%"}}
          
          md={3}
        >
          <Typography
            level="h1"
            fontSize="lg"
            sx={{ fontWeight: "bold", color: "#fff", marginBottom: "5px" }}
          >
            Rapid Automation Group
          </Typography>
          <Typography
            level="body2"
            sx={{
              fontWeight: "",
              color: listItemColor,
            }}
          >
            <GoLocation />
            {"   "} Candor Tech space, Sector - 135,
            <br />
            Genpact India Pvt LTD <br />
            Noida - Uttar Pradesh <br />
            201304
            <br />
            <br />
            <BsPhone /> +91-9999356344
            <br />
            <br />
            <BsMailbox /> rag_team@genpact.com
          </Typography>
        </Grid>

        <Grid
          xs={12}
          sx={mobile || !landscape ? { ml: "1%" } : {ml:"-1%"}}
          md={3}
        >
          <Typography
            level="h2"
            fontSize="lg"
            sx={
              mobile || !landscape
                ? {fontWeight: "bold",
                  color: "#fff",
                  textAlign: "left",
                  marginLeft: "10px",}
                : {
                    fontWeight: "bold",
                    color: "#fff",
                    textAlign: "left",
                    marginLeft: "10px",
                  }
            }
          >
            Our Capabilities
          </Typography>
          <List sx={{ listStyle: "none", padding: 0 }}>
            {capabilitiesList.map((item) => (
              <ListItem key={item}>
                <Typography
                  level="body2"
                  sx={{
                    fontWeight: "",
                    color: listItemColor,
                  }}
                >
                  {item}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>

        <Grid xs={12} sx={mobile || !landscape ? {ml: "1%"} : {ml:"-9%"}}  md={3}>
          <Typography
            level="h2"
            fontSize="lg"
            sx={{
              fontWeight: "bold",
              color: "#fff",
              textAlign: "left",
              marginLeft: "10px",
            }}
          >
            Businesses
          </Typography>
          <List sx={{ listStyle: "none", padding: 0 }}>
            {clients.map((business) => (
              <ListItem key={business}>
                <Typography
                  level="body2"
                  sx={{
                    fontWeight: "",
                    color: listItemColor,
                  }}
                >
                  {business}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid xs={12} sx={mobile || !landscape ? {ml: "-3.2%"} : {ml:"-10%"}} md={3}>
          <Typography
            level="h2"
            fontSize="lg"
            mb={1}
            sx={{
              // fontWeight: "bold",
              color: "#fff",
            }}
          >
            Reach out to us so we can guide you
          </Typography>
          <Button component={Link} to="/contact-us">
            Write to us
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
