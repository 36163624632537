import React, { useState } from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/joy";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import JetheshPulikkandiyil from "../../assets/client-pictures/JetheshPulikkandiyil.png";
import SandeepThaney from "../../assets/client-pictures/SandeepThaney.png";
import HemantMalik from "../../assets/client-pictures/HemantMalik.png";
import AnupamKushwaha from "../../assets/client-pictures/AnupamKushwaha.png";
import RatnaKishoreDV from "../../assets/client-pictures/RatnaKishoreDV.png";
// import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
// import { useLandScapeMode, useMobileMode } from "components/Responsive";
import SuccessStories from "../../assets/icons/values-thumb.jpg";
import Badri from "../../assets/icons/badri.png";
import { useLandScapeMode, useMobileMode } from "components/Responsive";

interface LeaderCard {
  name: string;
  designation: string;
  quote: string;
  logoURL: string;
  style?: object;
}

interface LeadersSpeakProps {
  cardData: LeaderCard[];
}

export const trimLeaderQuote = (quote: string) => {
  const trimmedLength = 100;
  if (quote.length > trimmedLength) {
    return quote.slice(0, trimmedLength) + "...";
  }
  return quote;
};

export const leadersData: LeaderCard[] = [
  {
    name: "Jethesh Pulikkandiyil",
    designation: "Vice President",
    quote:
      "Orion has significantly changed the way we run our transactional procurement processes. The tool allows you to real time monitor your queues, individual performances, team's capacity utilization etc. which allows the Supervisors to repurpose the resources based on the queue status. I appreciate the Orion Development Team for spending time with my Ops team to understand our business and customize the tool to meet our requirements. With every version, Orion has become more effective in day-to-day operations. Great Performance Management Tool!",
    logoURL: JetheshPulikkandiyil,
  },
  {
    name: "Sandeep Thaney",
    designation: "Global Delivery Leader",
    quote:
      "Orion is a powerful productivity tool that streamlines workflow management by measuring average handling time (AHT) and providing insights into agent performance. Its near real-time reporting helps managers to plan optimum capacity and make data-driven decisions on training needs. Deployed in Carlsberg, it enhanced operational efficiency, reduced manual effort, and boosted productivity.",
    logoURL: SandeepThaney,
  },
  {
    name: "Hemant Malik",
    designation: "Vice President",
    quote:
      "Orion, developed by RAG team, is a powerful tool for tracking daily volume, productivity, average handling time (AHT), and process health. It offers insights into agent performance to identify variations and improvement opportunities. Initially used in finance, it is now being implemented across human resources outsourcing (HRO) and supply chain management (SCM). The development team's agility allows for quick customizations.",
    logoURL: HemantMalik,
  },
  {
    name: "Anupam Kushwaha",
    designation: "Vice President",
    quote:
      "Orion 2.0 has transformed Carlsberg, Mattel, and Unilever North America by enabling Front Line Managers to track daily productivity and conduct 'time and motion' studies. Despite initial resistance due to new workflows, effective change management revealed its benefits, ensuring prioritization adherence and preventing 'cherry-picking.'",
    logoURL: AnupamKushwaha,
  },
  {
    name: "Ratna Kishore DV",
    designation: "Assistant Vice President",
    quote:
      "We appreciate your support during this process. With Orion 2.0's success in India, we are excited to extend it to other Danone delivery centers, enhancing efficiency and streamlining operations. Thank you for being part of this journey. We look forward to continued collaboration as we expand Orion 2.0 across Danone.",
    logoURL: RatnaKishoreDV,
  },
  {
    name: "Badri Natarajan",
    designation: "Vice President",
    quote:
      "The NDA automation has been extremely useful for Genpact and Mondelez for the reasons below:. Automatic triggers to employees joining the account that ensure we have NDA signed. Repository of NDA forms in soft copy for any future reference. Automated reconciliation between NDA forms and LAN ID’s to ensure client LAN ID’s are deactivated within 24 hours of employee exiting/ moving out of the account. Ensures we are 100% complaint to internal controls Daily/ Real time dashboard that shows status by tower, SDL and FLM’s. This is small, powerful, and highly replicable initiative that other accounts can consider.",
    logoURL: Badri,
  },
  {
    name: "Sambit Mohanty",
    designation: "Senior Manager",
    quote:
      "Thank you for developing the Proactive Tracker tool for our proactive team. It has greatly benefited us in several ways. It has provided us with more control over projects and tracking system. we faced challenges in managing the large volume of data. However, with the Proactive Tracker's capability to store and extract data efficiently, we no longer have to worry about data overload. With the integration of Power BI, we can now generate dynamic and visually appealing reports that provide a comprehensive view of our procurement activities Overall, the Proactive Tracker has reduced workload, increased efficiency, and streamlined our procurement processes.",
    logoURL: "",
  },
];

export const LeaderCard = ({
  name,
  designation,
  quote,
  logoURL,
  style = {},
}: LeaderCard) => {
  return (
    <Card sx={{ width: 450, ...style }}>
      <div>
        <Typography level="h5">{name}</Typography>
        <Typography level="body4">{designation}</Typography>
      </div>
      <CardContent orientation="horizontal">
        <img
          style={{ height: 90, width: 85, paddingTop: 5 }}
          src={logoURL}
          alt="logo"
        />
        <Typography level="body3" sx={{ color: "black", textAlign: "justify" }}>
          {quote}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const LeadersSlider: React.FC<LeadersSpeakProps> = ({ cardData }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const itemsPerPage = 2;
  const mobile = useMobileMode();
  const landscape = useLandScapeMode();

  

  const handlePreviousCard = () => {
    if (currentIndex - itemsPerPage >= 0) {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  };

  const handleNextCard = () => {
    if (currentIndex + itemsPerPage < cardData.length) {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  };

  return (
    <div
      style={
        mobile || !landscape
          ? {
              display: "flex",
              // flexDirection: "column",
              justifyContent: "space-between",

              marginTop: "-25%",
              width: "100%",
              // backgroundColor: "lightblue",
              background:
                "linear-gradient(to right, white 2%, rgb(255, 138, 0) 10%, rgb(229, 46, 113) 85%, white 100%)",
            }
          : {
              display: "flex",
              marginTop: "-25%",
              // backgroundColor: "lightblue",
              background:
                "linear-gradient(to right, white 2%, rgb(255, 138, 0) 10%, rgb(229, 46, 113) 85%, white 100%)",
            }
      }
    >
      {/* <div
        style={mobile || !landscape ? { width: "70%", marginLeft: "5%"} : {}}
      > */}
      <Box
        sx={
          mobile || !landscape
            ? {
                width: "40%",
                height: "400px",
                overflow: "hidden",
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                marginLeft: "2%",
              }
            : {
                width: "30%",
                height: "400px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // margin: "auto",
              }
        }
      >
        <img src={SuccessStories} alt="Successstory" />
      </Box>
      {/* </div> */}
      {/* <div style={mobile || !landscape ? { width: "100%" } : {}}> */}
      <Box
        sx={
          mobile || !landscape
            ? {
                width: "80%",
                height: "410px",
                display: "flex",
                flexDirection: "row",
                overflow: "hidden",
                alignItems: "center",
                // margin: "auto",
                paddingLeft: "2px",
              }
            : {
                width: "55%",
                height: "350px",
                display: "flex",
                overflow: "hidden",
                alignItems: "center",
                margin: "auto",
                paddingLeft: "10px",
              }
        }
        gap={2}
      >
        <div style={{ width: "3%", color: "black" }}>
          <Button
            onClick={handlePreviousCard}
            disabled={currentIndex === 0}
            variant="plain"
          >
            <FaArrowLeft />
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              height: "10%",
              textAlign: "center",
              paddingBottom: "20px",
            }}
          >
            <Typography level="h2" fontWeight={800} sx={{ color: "white" }}>
              Our Success Stories!!!
            </Typography>
          </div>
          <div
            style={
              mobile || !landscape
                ? {
                    width: "98.5%",
                    height: "90%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                  }
                : {
                    width: "91.5%",
                    height: "90%",
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                  }
            }
          >
            
            {cardData
              .slice(currentIndex, currentIndex + itemsPerPage)
              .map((leader, index) => {
                return (
                  <LeaderCard
                    key={index}
                    designation={leader.designation}
                    logoURL={leader.logoURL}
                    name={leader.name}
                    quote={leader.quote}
                    style={
                      mobile || !landscape
                        ? {
                            boxShadow: "lg",
                            transition: "box-shadow 0.3s ease-in-out",
                            height: "350px",
                            width: "100%",
                          }
                        : {
                            boxShadow: "lg",
                            transition: "box-shadow 0.3s ease-in-out",
                            height: "290px",
                            minWidth: "460px",
                          }
                    }
                  />
                );
              })}
          </div>
        </div>
        <div
          style={
            mobile || !landscape
              ? { width: "5%", color: "black", marginLeft: "-0.5%" }
              : { width: "5%", color: "black", marginLeft: "-1.5%" }
          }
        >
          <Button
            onClick={handleNextCard}
            disabled={currentIndex + itemsPerPage > cardData.length}
            variant="plain"
          sx={mobile || !landscape ? {marginLeft: "-100%"} : {}}
          >
            <FaArrowRight />
          </Button>
        </div>
      </Box>
    </div>
    // </div>
  );
};
