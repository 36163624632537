import React from "react";
import { Avatar, Grid, Stack, Typography } from "@mui/joy";
import {
  FaAddressCard,
  FaDeviantart,
  FaGithub,
  FaGitlab,
  FaInternetExplorer,
  FaLinkedin,
  FaLocationArrow,
} from "react-icons/fa";
import { FiMail, FiPhone } from "react-icons/fi";
import { SiResearchgate } from "react-icons/si";
import details from "assets/Details";
import LinkCarousel, { Link } from "./LinkCarousel";
import {
  Default,
  Mobile,
  useLandScapeMode,
  useMobileMode,
} from "../../Responsive";
import { MdOutlineWeb } from "react-icons/md";

export default function Contact() {
  const mobile = useMobileMode();
  const landscape = useLandScapeMode();

  return (
    <Stack
      gap={3}
      marginBottom={mobile || !landscape ? "7rem" : 0}
      sx={{
        zIndex: 1,
        textAlign: mobile || !landscape ? "center" : "left",
        alignItems: mobile || !landscape ? "center" : undefined,
        display: "flex",
        flexDirection: mobile || !landscape ? "column" : "row",
        // justifyContent: "space-between",
        width: "100%",
        marginLeft: "5%",
      }}
    >
      <Mobile>
        <Avatar
          alt="Email"
          color="warning"
          sx={(theme) => ({
            position: "relative",
            border: "none",
            outline: `2px solid ${theme.palette.warning[500]}`,
            boxShadow: `0 0 40px 5px rgba(${theme.palette.warning.mainChannel} / 0.4)`,
            overflow: "visible",
            marginTop: "3rem",
            "&::before": {
              content: '""',
              position: "absolute",
              top: "-5rem",
              height: "5rem",
              // width: "2px",
              background: `linear-gradient(to bottom, transparent, ${theme.palette.warning[400]})`,
            },
          })}
        >
          <FiMail />
        </Avatar>
      </Mobile>
      <div style={{ width: "35%" }}>
        <Typography level="h2" sx={{ position: "relative" }} id="contact">
          <Default>
            <Avatar
              alt="Email"
              color="warning"
              sx={(theme) => ({
                position: "absolute",
                left: "-49px",
                top: "0",
                transform: "translateX(-50%)",
                border: "none",
                outline: `2px solid ${theme.palette.warning[500]}`,
                boxShadow: `0 0 40px 5px rgba(${theme.palette.warning.mainChannel} / 0.4)`,
              })}
            >
              <FiMail />
            </Avatar>
          </Default>
          Get{" "}
          <Typography color="warning" fontWeight="xl">
            in touch
          </Typography>
        </Typography>

        <Typography level="h5" textColor="text.tertiary">
          Feel free to{" "}
          <Typography textColor="text.primary">contact us</Typography> if you
          have any questions or suggestions. We are always open to new{" "}
          <Typography textColor="text.primary">
            ideas and opportunities
          </Typography>
          .
        </Typography>
      </div>

      <div style={{ width: mobile || !landscape ? "87%" : "55%", marginLeft: mobile || !landscape ? "-20%" : "0"}}>
        <Grid container flex={1} flexDirection="row">
          <Grid xs={3}>
            <Link
              url={`mailto:${details.contact.email}`}
              icon={<FiMail />}
              title="Email"
              color="#ea4335"
              variant="plain"
              increaseSizeForIcon={true}
            />
          </Grid>
          <Grid xs={3}>
            <Link
              url={`tel:${details.contact.phone}`}
              icon={<FiPhone />}
              title="Phone"
              color="#ea4335"
              variant="plain"
              increaseSizeForIcon={true}
            />
          </Grid>
          <Grid xs={3}>
            <Link
              url={details.contact.website}
              icon={<MdOutlineWeb />}
              title="Visit us"
              color="#ea4335"
              variant="plain"
              increaseSizeForIcon={true}
            />
          </Grid>
          <Grid xs={3}>
            <Link
              url={details.contact.address}
              icon={<FaLocationArrow />}
              title="Locate us"
              color="#ea4335"
              variant="plain"
              increaseSizeForIcon={true}
            />
          </Grid>
        </Grid>
      </div>
    </Stack>
  );
}
