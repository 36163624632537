const details = {
  name: {
    first: "RAG",
    last: "",
    nickname: "",
  },

  contact: {
    address: "https://maps.app.goo.gl/MpYF2v2U2cmmhY9Y6",
    email: "RAG_Team@genpact.com",
    phone: "+91-(9999356344)",
    website: "https://rapid-automation-group.centralindia.cloudapp.azure.com",
  },
};

export default details;
