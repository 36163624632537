import { Box, Stack, Typography, useColorScheme } from "@mui/joy";
import { easings, useSpringRef } from "@react-spring/web";
// import balloonMin from "assets/balloon.min.webp";
// import balloon from "assets/balloon.webp";
// import planetDarkMin from "assets/planet_dark.min.webp";
// import planetDark from "assets/planet_dark.webp";
// import planetLightMin from "assets/planet_light.min.webp";
// import planetLight from "assets/planet_light.webp";
// import skyMin from "assets/sky.min.webp";
// import sky from "assets/sky.webp";
// import spaceStationMin from "assets/space-station.min.webp";
// import spaceStation from "assets/space-station.webp";
// import ProgressiveImage from "components/ProgressiveImage";
import React, { useEffect } from "react";
import { Parallax } from "react-scroll-parallax";
import { useMobileMode } from "../Responsive";
import MainPageBackGroundImage from "assets/icons/delivery-automation.webp";

export const transitionConfig = {
  duration: 1000,
  easing: easings.easeInOutExpo,
};

export default function Illustrations() {
  const skyTransRef = useSpringRef();
  const planetTransRef = useSpringRef();
  const accessoryTransRef = useSpringRef();

  const { colorScheme } = useColorScheme();
  const mobile = useMobileMode();

  // const skyTransition = useTransition(colorScheme, {
  //   ref: skyTransRef,
  //   initial: null,
  //   keys: null,
  //   from: { opacity: 0, top: "0rem" },
  //   enter: { opacity: 1, top: "0vh" },
  //   leave: { opacity: 0, top: "-100rem" },
  //   config: transitionConfig,
  // });

  // const [planetTransition] = useTransition(
  //   colorScheme,
  //   {
  //     ref: planetTransRef,
  //     keys: null,
  //     initial: null,
  //     from: {
  //       opacity: 0,
  //       top: "0rem",
  //       scale: 0.5,
  //       zIndex: -1,
  //     },
  //     enter: {
  //       opacity: 1,
  //       top: mobile ? "-50rem" : "-44rem",
  //       scale: 1,
  //       zIndex: 0,
  //     },
  //     leave: {
  //       opacity: 1,
  //       top: "-100rem",
  //       scale: 0.7,
  //       zIndex: 0,
  //     },
  //     config: transitionConfig,
  //   },
  //   [colorScheme, mobile]
  // );

  // const [accessoryTransition] = useTransition(
  //   colorScheme,
  //   {
  //     ref: accessoryTransRef,
  //     keys: null,
  //     initial: null,
  //     from: {
  //       opacity: 0,
  //       top: "20rem",
  //       scale: 0.5,
  //       zIndex: -1,
  //     },
  //     enter: {
  //       opacity: 1,
  //       top: mobile ? "5rem" : "12rem",
  //       scale: 1,
  //       zIndex: 0,
  //     },
  //     leave: {
  //       opacity: 0,
  //       top: "-100rem",
  //       scale: 0.7,
  //       zIndex: 0,
  //     },
  //     config: transitionConfig,
  //   },
  //   [colorScheme, mobile]
  // );

  useEffect(() => {
    planetTransRef.start();
    accessoryTransRef.start();
    skyTransRef.start();
  }, [colorScheme, planetTransRef, accessoryTransRef, skyTransRef]);

  useEffect(() => {
    planetTransRef.start({
      to: {
        top: mobile ? "-50rem" : "-44rem",
      },
    });
    accessoryTransRef.start({
      to: {
        top: mobile ? "5rem" : "12rem",
      },
    });
  }, [mobile, planetTransRef, accessoryTransRef]);

  useEffect(() => {
    document.body.style.transition = `background-color ease ${
      transitionConfig.duration / 1000
    }s, color ease ${transitionConfig.duration / 1000}s`;
    return () => {
      document.body.style.transition = "";
    };
  }, []);

  return (
    // <Parallax speed={-20} startScroll={0} disabled={mobile}>
    <>
      <Box
        component="div"
        sx={{
          position: "relative",
          width: "100%",
          height: "100vh",
          pointerEvents: "none",
          "*": {
            transition: `background-color ${
              transitionConfig.duration / 1000
            }s ease, color ${transitionConfig.duration / 1000}s ease`,
          },
        }}
      >
        <div style={{ width: "100%" }}>
          <img
            src={MainPageBackGroundImage}
            alt="pageback"
            style={{
              margin: "0 auto",
              width: "100%",
              maskImage: "linear-gradient(to bottom, #fff, transparent 99.0%)",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            top: 45,
            zIndex: 999,
            width: "100vw",
          }}
        >
          <Stack p="20px" gap="60px">
            <Typography
              level="h1"
              sx={{
                position: "relative",
                textShadow: "2px 2px 2px black",
                color: "#21A2B2",
              }}
              id="profile"
              fontWeight="xl"
            >
              Rapid{" "}
              <Typography
                alignItems="center"
                sx={{ color: "#F7525B", textShadow: "2px 2px 2px black" }}
              >
                Automation{" "}
              </Typography>
              <Typography
                sx={{
                  position: "relative",
                  textShadow: "2px 2px 2px black",
                  color: "#1C2B4B",
                }}
                id="profile"
              >
                Group
              </Typography>
            </Typography>
          </Stack>
        </div>
      </Box>
      {/* </Parallax> */}
    </>
  );
}
