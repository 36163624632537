import { IconType } from "react-icons";
import { FaCodeBranch, FaCommentAlt, FaHeart, FaStar } from "react-icons/fa";
import { FiFigma, FiGithub } from "react-icons/fi";
import { SiResearchgate } from "react-icons/si";
import { TbBrandDeviantart } from "react-icons/tb";

export const platforms = [
  "github",
  "figma",
  "deviantart",
  "researchgate",
] as const;
export type Platform = (typeof platforms)[number];

export const platformDetails: Record<
  Platform,
  {
    label: string;
    sublabel: string;
    icon: IconType;
  }
> = {
  github: {
    label: "GitHub",
    icon: FiGithub,
    sublabel: "Code repository",
  },
  figma: {
    label: "Figma",
    icon: FiFigma,
    sublabel: "Design prototype",
  },
  deviantart: {
    label: "DeviantArt",
    icon: TbBrandDeviantart,
    sublabel: "Artwork",
  },
  researchgate: {
    label: "ResearchGate",
    sublabel: "Research paper",
    icon: SiResearchgate,
  },
};

export const interactions = ["stars", "forks", "comments", "likes"] as const;
export type Interaction = (typeof interactions)[number];

export const interactionDetails: Record<
  Interaction,
  { label: string; icon: IconType }
> = {
  stars: { label: "Stars", icon: FaStar },
  forks: { label: "Forks", icon: FaCodeBranch },
  comments: { label: "Comments", icon: FaCommentAlt },
  likes: { label: "Likes", icon: FaHeart },
};

export interface Project {
  title: string;
  description?: string;
  thumbnail?: string;
  source: string;
  demo?: string;
  language?: string;
  createdAt?: string;
  updatedAt?: string;
  interactions?: Partial<Record<Interaction, number>>;
  platform: Platform;
}

export interface ProjectCardNew {
  title: string;
  description?: string;
  demo?: string;
  language?: string;
  pptLink?: string;
}

export const projectsList: ProjectCardNew[] = [
  {
    title: "ORION (The Production Management Tool)",
    description:
      "A Tool for Boosting Process Efficiency. Designed by RAG, it provides automation solutions that eliminate manual efforts, streamlining workflows, optimizing time & increase productivity. It allows peers to focus on strategic tasks, improving overall operational efficiency through real-time production visibility.",
    demo: "https://genpactonline-my.sharepoint.com/:v:/g/personal/703372639_genpact_com/EXtWODe4VWZAgf5nTcCCDTsBo6K5vOQJ-Yk7eaiJpcmCUg?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D&e=zvlxWt",
    language: "Power App & Power BI",
    pptLink: "https://genpactonline.sharepoint.com/:p:/s/ORION2.0Demonstration/EWOTz8nNtQtKtVPdtoDgnv0BPQ32iv2xYbrZBbOOmFDbyw?e=x03Dxv"
  },
  {
    title: "ORION e-Master (The E-mail Management Tool)",
    description:
      "Orion e-Master facilitates the management of both shared and individual mailboxes by offering real-time monitoring capabilities. ORION e-master provides the feasibility to track the digital footprint of deleted emails. Facilitates the assignment of each email to specific user. Each email is associated with a real-time status and a designated assignee.",
    demo: "https://genpactonline.sharepoint.com/:v:/s/ORION2.0Demonstration/EVYJwrVwxH1NhKgFGiAJ_dYBIivFtqVAxgRy-oIaYKK8_w?e=ODvgRf&nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJTdHJlYW1XZWJBcHAiLCJyZWZlcnJhbFZpZXciOiJTaGFyZURpYWxvZy1MaW5rIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXcifX0%3D",
    language: "Power App & Power BI",
    pptLink: "https://genpactonline.sharepoint.com/:p:/s/ORION2.0Demonstration/Efrv1t1ohSpCiIo9A3YypF4BI_odR61c_BYn92oEOaGHEw?e=uBPaNm"
  },

  {
    title: "Standardization & Automation Index Measure (PSI / PAI) Web App",
    description:
      "We have developed this Full Stack application for users, to fill out the survey based on their mappings of Tower, Process, Sub-process, region, etc for both As Is and To Be scenarios. To extract and understand the Survey data of users using Power BI Dashboards which would ingest data from the Full Stack application's DB.",
    demo: "https://psi-pai-webapp.centralindia.cloudapp.azure.com/",
    language: "React, Python & SQL",
    pptLink: "https://genpactonline-my.sharepoint.com/:p:/g/personal/703390488_genpact_com/EcHoZQ6H0SlHhsF27pKgYpsBZYmOKG-SU14gRbQZIw0_Rw?e=o8b6rm"
  },
  {
    title: "Distress Inventory Management",
    description:
      "We implemented VBA to report out Periodic Distress Inventory and perform their In-Out Transfers from Warehouse. this helps in reducing wastage and preventing items in warehouse from being expired.Also, this QPA emails to customers regarding any Discontinued items and its replacement thus preventing any Refunds/Deductions.",
    language: "VBA",
  },
  {
    title: "Visualization Projects",
    description: "Visualization ( Analysis, AP reporting, AR reporting, MEC reporting and other reporting using advance excel, tableau & power Bi). Offering insights on GL close activities (C&H accounts)Smart narrative building (Key metrics. Trending, critical information, predictions, interactive visuals) Rich Foods, Mars, Pladis, Danone, Kraft Heinz, Tropicana, THS, & New transition accounts.",
    language: "Power BI, Tableau, Power Applications, Macro",
  },
  {
    title: "Vendor Recon",
    description:
      "A QPA Project to perform Vendor Reconciliation. It captures the Vendor's Statement of Account(SOA), Extracts the SAP data for relevant Vendor And Reconciles basis given Rules. Not only it Reonciles but also provides the Summary output sheets. Tool is capable of handing number of Vendor Reconciliations. Not only it helps in saving time but also provides unmatchable accuracy with verified controls.",
    language: "VBA",
  },
  {
    title: "Proactive Tracker",
    description:"The Proactive Project Tracker serves as an essential day-to-day tool for TSM Buyers to monitor project information efficiently and accurately for Mondelez Account. It facilitates tracking across all 10 stages, beginning with data gathering, proceeding through NDA and RFQ processes, advancing through contract award and signature stages, and ending with project closure and the capture of CSAT scores provided by the business. This tool plays a critical role in maintaining and analyzing project data, ensuring oversight of expenditures, savings, and key performance indicators (KPIs)",
    language: "VBA",
  },
  {
    title: "NDA Automation",
    description:"Ops and HR team identified issues with handling Non-Disclosure Agreement in Mondelez Account. During internal controls review, spotted some copies of NDAs were missing and had no proper system for keeping track of them. Unnecessary physical copies were being kept without any record of the owner.",
    language: "VBA",
  },
  {
    title: "Claim uploading",
    description:
      "Extract PDF file from Email preparing excel file using pdf after that start posting into Web Authrizer portal",
    language: "Python",
  },

];

const rankingCoefficients = {
  content: 0.5,
  thumbnail: 0.5,
  demo: 1,
  rarity: 2,
  interactions: 0.5,
};

/**
 * Rank a project based on its content, thumbnail, demo and rarity.
 */
export function rank(project: Project, catalog: Project[]): number {
  const contentLength =
    project.title.length + (project.description?.length || 0);
  const contentPertinence =
    contentLength > 150
      ? rankingCoefficients.content - (contentLength - 150) / 350
      : contentLength / 150;

  const hasThumbnail = project.thumbnail ? rankingCoefficients.thumbnail : 0;
  const hasDemo = project.demo ? rankingCoefficients.demo : 0;

  const minorityCoefficient =
    rankingCoefficients.rarity *
    (catalog.filter((item) => item.platform === project.platform).length /
      catalog.length);

  const interactionCoefficient =
    Object.values(project.interactions ?? {}).reduce(
      (acc, value) => acc + value,
      0
    ) * rankingCoefficients.interactions;

  return (
    contentPertinence +
    hasThumbnail +
    hasDemo +
    minorityCoefficient +
    interactionCoefficient
  );
}
