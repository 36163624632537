import React from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import Typography from "@mui/joy/Typography";
import { Avatar, Grid } from "@mui/joy";
import SandeepDasProf from "../../assets/profile-pictures/sandeep-das.jpeg";
import PradeepProf from "../../assets/profile-pictures/pradeep-sharma.jpeg";
import AbishekChaudhary from "../../assets/profile-pictures/Abhishek-Chaudhary.png";
import DeepanshuVerma from "../../assets/profile-pictures/Deepanshu-Verma.png";
import DushyantRana from "../../assets/profile-pictures/Dushyant-Rana.jpg";
import HarshitaAgrawal from "../../assets/profile-pictures/Harshita-Agrawal.png";
import JaiPrakash from "../../assets/profile-pictures/Jai-Prakash.png";
import JugrajCheema from "../../assets/profile-pictures/Jugraj-Cheema.png";
import MonikaKumari from "../../assets/profile-pictures/Monika-Kumari.png";
import MuskanSharma from "../../assets/profile-pictures/Muskan-Sharma.png";
import NitinKumar from "../../assets/profile-pictures/Nitin-Kumar.png";
import Om from "../../assets/profile-pictures/Om.png";
import PrashantChawariya from "../../assets/profile-pictures/Prashant-Chawariya.png";
import Raju from "../../assets/profile-pictures/Raju.jpg";
import RaviSharma from "../../assets/profile-pictures/Ravi-Sharma.png";
import RupeshKumar from "../../assets/profile-pictures/Rupesh-Kumar.png";
import SherryJindal from "../../assets/profile-pictures/Sherry-Jindal.png";
import ShivaniGupta from "../../assets/profile-pictures/Shivani-Gupta.png";
import TanujMondal from "../../assets/profile-pictures/Tanuj-Mondal.jpg";
import VineetGupta from "../../assets/profile-pictures/Vineet-Gupta.png";
import DeepakKumar from "../../assets/profile-pictures/DeepakKumar.png";

const chunkArray = (array: StyledNodeProps[], size: number) => {
  const result = [];
  for (let i = 0; i < array?.length; i += size) {
    result.push(array?.slice(i, i + size));
  }
  return result;
};

interface StyledNodeProps {
  photoURL?: string;
  name: string;
  designation?: string;
  department: boolean;
  children?: StyledNodeProps[];
  teamMembers?: string[];
  deptLead?: boolean;
}

const StyledNode: React.FC<StyledNodeProps> = ({
  photoURL,
  name,
  designation,
  department,
  deptLead,
  teamMembers,
}) => {
  const AvatarComponent = () => {
    const avatarSize = "60px";

    if (photoURL && photoURL !== "") {
      return (
        <Avatar sx={{ width: avatarSize, height: avatarSize }}>
          <img
            src={photoURL}
            style={{ width: avatarSize, height: avatarSize }}
          />
        </Avatar>
      );
    } else if (department) {
      return null;
    } else {
      const initials = name.split(" ").map((word) => {
        return word[0].toUpperCase();
      });
      return <Avatar>{initials}</Avatar>;
    }
  };

  let width = "150px";
  if (teamMembers && teamMembers.length > 0) {
    width = "200px";
  }

  return (
    <Card
      variant="outlined"
      size="sm"
      sx={{
        display: "inline-block",
        width: width,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AvatarComponent />
        {department ? (
          <Typography level="body3">{name.toUpperCase()}</Typography>
        ) : (
          <Typography level="body2" textColor="black">
            {name}
          </Typography>
        )}
        {designation && !department && (
          <Typography level="body3">{designation}</Typography>
        )}

        {teamMembers && teamMembers.length > 0 && (
          <Grid container mt={1}>
            {teamMembers.map((member) => {
              return (
                <Grid
                  xs={12}
                  display="flex"
                  textAlign="left"
                  flexDirection="column"
                  pt={0.5}
                >
                  <Typography level="body2" textColor="black">
                    - {member}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

const data: StyledNodeProps = {
  photoURL: SandeepDasProf,
  name: "Sandeep Das",
  designation: "Sponsor",
  department: false,
  children: [
    {
      photoURL: PradeepProf,
      name: "Pradeep Sharma",
      designation: "AVP",
      department: false,
      children: [
        {
          photoURL: VineetGupta,
          name: "Vineet Gupta",
          designation: "Manager",
          department: false,
          deptLead: true,
          children: [],
        },
        {
          photoURL: TanujMondal,
          name: "Tanuj Mondal",
          department: false,
        },
        {
          photoURL: PrashantChawariya,
          name: "Prashant Chawraia",
          department: false,
        },
        {
          photoURL: RaviSharma,
          name: "Ravi Kumar Sharma",
          department: false,
        },
        {
          photoURL: SherryJindal,
          name: "Sherry Jindal",
          department: false,
        },
        { photoURL: Raju, name: "Raju Raju", department: false },
        { photoURL: Om, name: "Om Prakash", department: false },
        {
          photoURL: JugrajCheema,
          name: "Jugraj Cheema",
          department: false,
        },
        {
          photoURL: DushyantRana,
          name: "Dushyant Rana",
          department: false,
        },
        {
          photoURL: DeepakKumar,
          name: "Deepak Kumar",
          department: false,
        },
        {
          photoURL: NitinKumar,
          name: "Nitin Kumar",
          designation: "Manager",
          department: false,
          deptLead: true,
          children: [],
        },
        {
          photoURL: ShivaniGupta,
          name: "Shivani Gupta",
          department: false,
        },
        {
          photoURL: HarshitaAgrawal,
          name: "Harshita Agarwal",
          department: false,
        },
        {
          photoURL: DeepanshuVerma,
          name: "Deepanshu Verma",
          department: false,
        },
        {
          photoURL: AbishekChaudhary,
          name: "Abhishek Chaudhary",
          department: false,
        },
        {
          photoURL: RupeshKumar,
          name: "Rupesh Kumar",
          department: false,
        },
        {
          photoURL: JaiPrakash,
          name: "Jai Prakash",
          department: false,
        },
        {
          photoURL: MuskanSharma,
          name: "Muskan Sharma",
          department: false,
        },
        {
          photoURL: MonikaKumari,
          name: "Monika Kumari",
          department: false,
        },
        {
          photoURL: "",
          name: "Mithun Kumar",
          department: false,
        },
      ],
    },
  ],
};

const OrgTree = () => (
  <div style={{ backgroundColor: "#fff" }}>
    <Tree
      lineWidth={"2px"}
      lineColor={"#00AECF"}
      lineBorderRadius={"10px"}
      label={
        <StyledNode
          photoURL={data.photoURL}
          name={data.name}
          department={false}
          designation={data.designation}
        />
      }
    >
      {data.children?.map((ragTeamMember) => {
        const maxChildPerRow = 10;
        const childGroups = chunkArray(
          ragTeamMember.children || [],
          maxChildPerRow
        );
        let secondGroupDetected = false;
        return (
          <TreeNode
            label={
              <StyledNode
                photoURL={ragTeamMember.photoURL}
                name={ragTeamMember.name}
                department={false}
                designation={ragTeamMember.designation}
              />
            }
          >
            <div
              id="parent-chaukas"
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {childGroups?.length > 0 &&
                childGroups.map((group, index) => {
                  secondGroupDetected = index > 0;
                  return (
                    <div
                      id="chaukas"
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingLeft: "9.5em",
                        paddingTop: secondGroupDetected ? 10 : 0,
                      }}
                    >
                      {group.map((child) => (
                        <TreeNode
                          label={
                            <StyledNode
                              photoURL={child.photoURL}
                              name={child.name}
                              department={false}
                            />
                          }
                        />
                      ))}
                    </div>
                  );
                })}
              {/* {ragTeamMember.children &&
              ragTeamMember.children.map((ragTeamDept) => {
                return (
                  <TreeNode
                    label={
                      <StyledNode
                        name={ragTeamDept.name}
                        department={ragTeamDept.department}
                        designation={ragTeamDept.designation}
                      />
                    }
                  ></TreeNode>
                );
              })} */}
            </div>
          </TreeNode>
        );
      })}
    </Tree>
  </div>
);

export default OrgTree;
