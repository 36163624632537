import { Button, Grid, Stack, Typography } from "@mui/joy";

import React from "react";
import Meta from "./Meta";
import { useLandScapeMode, useMobileMode } from "./Responsive";
import details from "assets/Details";
import { Link } from "react-router-dom";
import { IoIosReturnLeft } from "react-icons/io";
// import { FaMinus, FaPlus } from "react-icons/fa";
import Item from "./capabilities/Item";
import { SkillButton } from "./capabilities/SkillButton";
import {
  Si365Datascience,
  SiJavascript,
  SiMicrosoftexcel,
  SiMysql,
  SiPowerapps,
  SiPowerautomate,
  SiPowerbi,
  SiPython,
  SiReact,
  SiVisualbasic,
} from "react-icons/si";
import Credits from "./sections/Credits";
import TechLifeCycle from "../assets/icons/skills.png";
import { MdLandslide } from "react-icons/md";

export default function Capabilities() {
  //   const SERVICE_ID: string = "service_lbfup5p";
  //   const TEMPLATE_ID: string = "template_cjun8rm";
  //   const PUBLIC_KEY: string = "PbHn1ZjEm0wbHWa49";
  // const [pressedAnalytics, setPressedAnalytics] = useState(false);
  // const [pressedDevelopment, setPressedDevelopment] = useState(false);
  // const openAnalytics = true;
  // const openDevelopment = true;
  // const [showAnalytics, setShowAnalytics] = useState(false);
  // const [showDevelopment, setShowDevelopment] = useState(false);

  const mobile = useMobileMode();
  const landscape = useLandScapeMode();
  return (
    <Stack>
      <div
          id="trio"
          style={{
            zIndex: 999,
            position: "fixed",
            width: "15px",
            height: "100vh",
            background:
              "linear-gradient(to bottom, #F7525B 10%, #1C2B4B 45%, #21A2B2 70%, #F7525B 100%)",
          }}
        ></div>
      <Stack
        width="100%"
        position="relative"
        overflow="hidden"
        sx={{
          paddingLeft: "var(--nav-safe-area-inset-left)",
          paddingBottom: "var(--nav-safe-area-inset-bottom)",
        }}
      >
        <Meta title={`Request - ${details.name.first}`} />
        <Stack
          padding={mobile ? "1rem" : "3rem"}
          gap={2}
          alignItems="start"
          sx={{
            background:
              "linear-gradient(to right, white 2%, rgb(255, 138, 0) 10%, rgb(229, 46, 113) 85%, white 100%)",
          }}
        >
          <Stack marginBottom="min(10vw, 3rem)" marginTop={mobile ? "10vw" : 0}>
            <Typography
              level="display1"
              fontSize="clamp(3rem, 15vw, 9rem)"
              fontWeight={200}
              lineHeight={1}
              sx={{
                position: "relative",
                marginLeft: ".25em",
                marginBottom: ".05em",
              }}
            >
              <Typography
                level="h2"
                sx={{
                  position: "absolute",
                  left: "-.6em",
                  bottom: "1.7em",
                  transform: "translateX(-50%) rotate(-90deg)",
                  fontSize: "clamp(.5rem, 3vw, 1.5rem)",
                }}
              >
                Check out
              </Typography>
              Our
            </Typography>
            <Typography
              level="display1"
              fontSize="clamp(3rem, 15vw, 8rem)"
              fontWeight={200}
              lineHeight={0.5}
              zIndex={1}
              fontFamily={'"Righteous", sans-serif'}
            >
              Capabilities
            </Typography>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            sx={
              mobile
                ? {
                    width: "100%",
                    "& > *:first-child": {
                      flex: 1,
                    },
                  }
                : {}
            }
          >
            <Button
              size="lg"
              component={Link}
              to="/"
              variant="outlined"
              color="neutral"
              endDecorator={<IoIosReturnLeft size="1.3em" />}
              sx={(theme) => ({
                marginTop: "1em",
                transition: "all ease .2s",
                position: "relative",
                borderRadius: "20px",
                boxShadow: "1px 1px grey",
                width: "fit-content",
                flexShrink: 0,
                padding: "1 2",
                background: theme.palette.background.body,

                "&:hover": {
                  // background: theme.palette.text.primary,
                  color: theme.palette.background.level1,
                  borderColor: theme.palette.text.primary,
                  "& > span > svg": {
                    transform: "translate(.6rem, .3rem) scale(1.2)",
                    filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.tertiary}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.secondary})`,
                  },
                },
                "&:active": {
                  transform: "scale(.98)",
                  "& > span > svg": {
                    transform: "translate(.6rem, .3rem) scale(1.1)",
                    filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1})`,
                  },
                },
                "& > span > svg": {
                  transition: "all ease .2s",
                },
              })}
            >
              Back to homepage
            </Button>
          </Stack>
        </Stack>

        <Grid container sx={{ display: "flex", flexDirection: "row",   marginTop: "4em" }}>
          <Grid
            container
            gap={2}
            ml={mobile || !landscape ? 5.0 : 9.8}
            mr={10}
            mt={-4}
            flexDirection="column"
            xs={mobile || !landscape ? 5 : 6}
          >
            <Item title="Analytics & Reporting">
              <Grid container gap={2}>
                <SkillButton
                  url=""
                  icon={<SiPowerbi />}
                  title="Power BI"
                  color="yellow"
                  key="power-bi"
                  variant="outlined"
                />
                <SkillButton
                  url=""
                  icon={<SiMicrosoftexcel />}
                  title="MS Excel"
                  color="yellow"
                  key="excel"
                  variant="outlined"
                />
                <SkillButton
                  url=""
                  icon={<Si365Datascience />}
                  title="Data Transformation & Cleanup"
                  color="yellow"
                  key="data"
                  variant="outlined"
                />
              </Grid>
            </Item>
            <Item title="Microsoft 365 Platform">
              <Grid container gap={2}>
                <SkillButton
                  url=""
                  icon={<SiPowerapps />}
                  title="Power App"
                  color="yellow"
                  key="power-app"
                  variant="outlined"
                />
                <SkillButton
                  url=""
                  icon={<SiPowerautomate />}
                  title="Power Automate"
                  color="yellow"
                  key="power-automate"
                  variant="outlined"
                />
                <SkillButton
                  url=""
                  icon={<SiVisualbasic />}
                  title="VBA"
                  color="yellow"
                  key="VBA"
                  variant="outlined"
                />
              </Grid>
            </Item>

            <Item title="Development">
              <Grid container gap={2}>
                <SkillButton
                  url=""
                  icon={<SiReact />}
                  title="React"
                  color="blue"
                  key="react"
                  variant="outlined"
                />
                <SkillButton
                  url=""
                  icon={<SiPython />}
                  title="Python"
                  color="blue"
                  key="python"
                  variant="outlined"
                />
                <SkillButton
                  url=""
                  icon={<SiJavascript />}
                  title="JavaScript"
                  color="blue"
                  key="java-script"
                  variant="outlined"
                />
                <SkillButton
                  url=""
                  icon={<SiMysql />}
                  title="SQL"
                  color="blue"
                  key="sql"
                  variant="outlined"
                />
              </Grid>
            </Item>
          </Grid>
          <Grid xs={4}>
            <img
              src={TechLifeCycle} alt="skillcycle"
              style={{ width: mobile || !landscape ? "135%" : "100%", height: mobile || !landscape ? "125%" : "110%", marginTop: "-90px", borderRadius: "48%" }}
            />
          </Grid>
        </Grid>
      </Stack>
      <Credits />
    </Stack>
  );
}
