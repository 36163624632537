/* eslint-disable no-nested-ternary */
import {
  Button,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useColorScheme,
} from "@mui/joy";
import React, { useEffect, useState } from "react";
import { useLandScapeMode, useMobileMode } from "components/Responsive";
import { Link, useLocation } from "react-router-dom";
import {
  BsHouse,
  BsHouseFill,
  BsJournalBookmark,
  BsJournalBookmarkFill,
  BsMoon,
  BsFilePersonFill,
  BsSun,
  BsFillPersonFill,
  BsInfoCircle,
  BsInfoCircleFill,
} from "react-icons/bs";
import {
  MdContactSupport,
  MdMarkEmailUnread,
  MdOutlineAutoMode,
} from "react-icons/md";
import genpactLogo from "../assets/icons/genpact-logo.png";
import { RiOrganizationChart } from "react-icons/ri";

const modes = ["light", "dark", "system"] as const;

function NavigationBarItem({
  icon,
  text,
  to,
  selectedIcon,
  layout = "vertical",
  selected,
  logo = false,
}: {
  icon: JSX.Element;
  text: string;
  to: string;
  selectedIcon?: JSX.Element;
  layout?: "vertical" | "horizontal";
  selected?: boolean;
  logo?: boolean;
}) {
  const mobile = useMobileMode();
  const landscape = useLandScapeMode();

  const getSelectedStyle = (
    selected: boolean = false,
    theme: any,
    logo: boolean = false
  ) => {
    if (logo) {
      return {
        "&:hover, &:active": {
          backgroundColor: mobile ? theme.palette.text.primary : "#1C2B4B",
          color: theme.palette.background.body,
        },
      };
    }

    if (selected) {
      return {
        backgroundColor: theme.palette.text.primary,
        color: selected
          ? theme.palette.background.body
          : theme.palette.text.primary,
        "&:hover, &:active": {
          backgroundColor: mobile
            ? theme.palette.text.primary
            : theme.palette.text.primary,
          color: theme.palette.background.body,
        },
      };
    } else {
      return {
        color: theme.palette.text.secondary,
        "&:hover, &:active": {
          backgroundColor: mobile
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
          color: theme.palette.text.primary,
        },
      };
    }
  };

  return layout === "vertical" ? (
    <Stack
      component={Link}
      to={to}
      alignItems="center"
      gap={1}
      sx={
        mobile || !landscape
          ? {
              cursor: "pointer",
              borderRadius: "0.5rem",
              fontSize: "1.3rem",
              paddingX: "1rem",
              textDecoration: "none",
            }
          : {
              cursor: "pointer",
              borderRadius: "0.5rem",
              fontSize: "1.3rem",
              paddingX: "1rem",
              textDecoration: "none",
            }
      }
    >
      <Button
        color="neutral"
        variant={selected ? "solid" : "plain"}
        sx={(theme) => {
          return {
            transition: "all 0.2s",
            padding: "0.3rem 1.1rem",
            borderRadius: "100vmax",
            minHeight: "fit-content",
            fontSize: "inherit",
            "& > svg": {
              transition: "all 0.2s",
            },
            "&:hover > svg": {
              transform: "scale(1.05)",
            },
            ...getSelectedStyle(selected, theme, logo),
          };
        }}
      >
        {selected ? selectedIcon || icon : icon}
      </Button>
      <Typography level="body3" fontWeight="700" textColor="text.secondary">
        {text}
      </Typography>
    </Stack>
  ) : (
    <Button
      component={Link}
      to={to}
      color="neutral"
      variant={selected ? "solid" : "plain"}
      sx={(theme) => ({
        minHeight: "fit-content",
        borderRadius: "100vmax",
        padding: ".6rem 1rem",
        transition: "all 0.2s",
        "& > svg": {
          transition: "all 0.2s",
        },
        "&:hover > svg": {
          transform: "scale(1.05)",
        },
        ...getSelectedStyle(selected, theme, logo),
      })}
      startDecorator={selected ? selectedIcon || icon : icon}
    >
      {text}
    </Button>
  );
}

export default function NavigationBar({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const location = useLocation();
  const { mode, setMode } = useColorScheme();

  const bottom = false;
  // useMobileMode();
  const landscape = false;
  // useLandScapeMode();
  const horizontal = true;
  // useMemo(() => !landscape && !bottom, [landscape, bottom]);

  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  const navigationRef = React.createRef<HTMLDivElement>();

  // Default to light mode
  // useEffect(() => {
  //   setMode('light')
  // })

  // Resize observer
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const [{ inlineSize: newWidth, blockSize: newHeight }] =
          entry.borderBoxSize;
        setWidth(newWidth);
        setHeight(newHeight);
      });
    });

    if (navigationRef.current) {
      resizeObserver.observe(navigationRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [navigationRef]);

  // Safe area insets
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--nav-safe-area-inset-top",
      landscape || bottom ? "0" : height ? `${height}px` : "3rem"
    );
    document.documentElement.style.setProperty(
      "--nav-safe-area-inset-bottom",
      bottom ? (height ? `${height}px` : "4.5rem") : "0"
    );
    document.documentElement.style.setProperty(
      "--nav-safe-area-inset-left",
      landscape ? (width ? `${width}px` : "5.5rem") : "0"
    );

    return () => {
      document.documentElement.style.removeProperty(
        "--nav-safe-area-inset-top"
      );
      document.documentElement.style.removeProperty(
        "--nav-safe-area-inset-bottom"
      );
      document.documentElement.style.removeProperty(
        "--nav-safe-area-inset-left"
      );
    };
  }, [landscape, bottom, width, height]);

  return (
    <>
      <Stack
        ref={navigationRef}
        direction={landscape ? "column" : "row"}
        sx={(theme) => ({
          position: "fixed",
          ...(bottom
            ? {
                bottom: 0,
                borderTop: `1px solid ${theme.palette.divider}`,
              }
            : {
                top: 0,
              }),
          left: 0,
          gap: 4,
          alignItems: "center",
          backgroundColor: `${theme.palette.background.level1}`,
          backdropFilter: "blur(10px)",
          webkitBackdropFilter: "blur(10px)",
          zIndex: 1000,
          ...(landscape
            ? {
                paddingY: "1.5rem",
                height: "100vh",
                width: "fit-content",
                borderRight: `1px solid ${theme.palette.divider}`,
              }
            : {
                padding: bottom ? ".5rem" : ".5rem 2rem",
                width: "100vw",
                height: "fit-content",
                borderBottom: bottom
                  ? undefined
                  : `1px solid ${theme.palette.divider}`,
              }),
        })}
      >
        <NavigationBarItem
          icon={
            <img
              src={genpactLogo}
              alt="genlogo"
              style={{ color: "black", width: "80px", height: "30px" }}
            />
          }
          selectedIcon={
            <img
              src={genpactLogo}
              alt="icon"
              style={{ color: "black", width: "50px", height: "20px" }}
            />
          }
          text=""
          layout={horizontal ? "horizontal" : "vertical"}
          to="/"
          selected={false}
          logo={true}
        />
        <Stack
          flex={1}
          // justifyContent={bottom ? "space-evenly" : "flex-start"}
          justifyContent={"center"}
          direction={landscape ? "column" : "row"}
          gap={1}
        >
          {!horizontal && <hr style={{ width: "100px" }} />}
          <NavigationBarItem
            icon={<BsHouse />}
            selectedIcon={<BsHouseFill />}
            text="Home"
            layout={horizontal ? "horizontal" : "vertical"}
            to="/"
            selected={location.pathname === "/"}
          />
          <NavigationBarItem
            icon={<BsInfoCircle />}
            selectedIcon={<BsInfoCircleFill />}
            text="About Us"
            layout={horizontal ? "horizontal" : "vertical"}
            to="/about"
            selected={location.pathname === "/about"}
          />
          <NavigationBarItem
            icon={<BsJournalBookmark />}
            selectedIcon={<BsJournalBookmarkFill />}
            text="Projects"
            layout={horizontal ? "horizontal" : "vertical"}
            to="/projects"
            selected={location.pathname === "/projects"}
          />
          <NavigationBarItem
            icon={<MdMarkEmailUnread />}
            selectedIcon={<MdMarkEmailUnread />}
            text="Request Rquirements"
            layout={horizontal ? "horizontal" : "vertical"}
            to="/request-requirements"
            selected={location.pathname === "/request-requirements"}
          />
          <NavigationBarItem
            icon={<BsFillPersonFill />}
            selectedIcon={<BsFilePersonFill />}
            text="Our Team's Expertise"
            layout={horizontal ? "horizontal" : "vertical"}
            to="/capabilities"
            selected={location.pathname === "/capabilities"}
          />
          <NavigationBarItem
            icon={<RiOrganizationChart />}
            selectedIcon={<RiOrganizationChart />}
            text="Organization"
            layout={horizontal ? "horizontal" : "vertical"}
            to="/org"
            selected={location.pathname === "/org"}
          />
          <NavigationBarItem
            icon={<MdContactSupport />}
            selectedIcon={<MdContactSupport />}
            text="Contact Us"
            layout={horizontal ? "horizontal" : "vertical"}
            to="/contact-us"
            selected={location.pathname === "/contact-us"}
          />
        </Stack>
        {horizontal ? (
          <Button
            variant="plain"
            color="neutral"
            size="lg"
            sx={{
              minHeight: "fit-content",
              borderRadius: "100vmax",
              padding: ".6rem 1rem",
              transition: "all 0.2s",
              fontSize: "var(--joy-fontSize-sm)",
            }}
            onClick={() => {
              if (mode)
                setMode(modes[(modes.indexOf(mode) + 1) % modes.length]);
            }}
            startDecorator={
              mode === "system" ? (
                <MdOutlineAutoMode />
              ) : mode === "light" ? (
                <BsSun />
              ) : (
                <BsMoon />
              )
            }
          >
            {`${
              mode === "system" ? "System" : mode === "light" ? "Light" : "Dark"
            } theme`}
          </Button>
        ) : (
          <Tooltip
            variant="soft"
            placement="right"
            title={`${
              mode === "system" ? "System" : mode === "light" ? "Light" : "Dark"
            } theme`}
          >
            <IconButton
              variant="plain"
              color="neutral"
              size="lg"
              sx={{
                transition: "all 0.2s",
                display: bottom ? "none" : undefined,
                borderRadius: "100vmax",
                padding: 2,
              }}
              onClick={() => {
                if (mode)
                  setMode(modes[(modes.indexOf(mode) + 1) % modes.length]);
              }}
            >
              {mode === "system" ? (
                <MdOutlineAutoMode />
              ) : mode === "light" ? (
                <BsSun />
              ) : (
                <BsMoon />
              )}
            </IconButton>
          </Tooltip>
        )}
      </Stack>
      {children}
    </>
  );
}
