import React, { useEffect, useState } from "react";
import {
  Button,
  Stack,
  Typography,
  Box,
  useColorScheme,
  Card,
  CardContent,
} from "@mui/joy";
import { animated, useSpringRef, useTransition } from "@react-spring/web";

import Meta from "../Meta";
import { useMobileMode } from "../Responsive";
import details from "../../assets/Details";
import { Link } from "react-router-dom";
import { IoIosReturnLeft } from "react-icons/io";
import Credits from "components/sections/Credits";
import ProgressiveImage from "components/ProgressiveImage";
import AboutUsImage from "../../assets/icons/aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa.jpg";
import CustomerExp from "../../assets/icons/want-a-better-customer-experience-align-customer-success-and-marketing.webp";
import GiveTake from "../../assets/icons/responsibility.jpg";
import AnalyticImage from "../../assets/icons/anlytics.jfif";
import DataValid from "../../assets/icons/Data-Quality-Validation-Rules-Explained-with-Examples.jpg";
import DataDriven from "../../assets/icons/what-is-data-driven-decision-making.jpg";
import Excellence from "../../assets/icons/excellence.jpg";

interface IOurValue {
  imageURL: string;
  name: string;
}
const OurValues: IOurValue[] = [
  { name: "Better Customer Experience", imageURL: CustomerExp },
  { name: "Give and Take responsibility", imageURL: GiveTake },
  { name: "Increased Analytics Maturity", imageURL: AnalyticImage },
  { name: "Data Quality Validation", imageURL: DataValid },
  { name: "Data-Driven Decisioning", imageURL: DataDriven },
  { name: "Inspire Excellence", imageURL: Excellence },
];
export default function About() {
  function ThemeAwareIllustration() {
    const { colorScheme } = useColorScheme();
    const [loaded, setLoaded] = useState(false);

    const transRef = useSpringRef();

    const transitions = useTransition(colorScheme, {
      ref: transRef,
      keys: null,
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0, filter: "blur(10px)", position: "absolute" },
    });

    useEffect(() => {
      if (loaded) {
        transRef.start();
      }
    }, );
    // [colorScheme, loaded]
    const imgSx = {
      position: "relative",
      marginTop: "-5rem",
      width: "100%",
      // WebkitMaskImage: "linear-gradient(to left,black 10%,transparent 80%)",
      // maskImage: "linear-gradient(to left,black 10%,transparent 99.99%)",
      // filter: "grayscale(1)",
    } as React.CSSProperties;

    return (
      <Stack
        sx={{
          position: "absolute",
          // right: "min(0rem, calc(100vw - 100rem))",
          width: "100%",
          height: "min(100%, 27rem)",
          overflow: "hidden",
          paddingTop: "var(--nav-safe-area-inset-top)",
          "&:after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            // backdropFilter: "blur(20px)",
            // mask: "linear-gradient(to left, transparent, black 60%)",
          },
        }}
      >
        {transitions((style, item) => {
          switch (item) {
            case "light":
              return (
                <animated.div style={style}>
                  <ProgressiveImage
                    src={AboutUsImage}
                    placeholder={AboutUsImage}
                    alt="Brutalist building by Arthur Swiffen"
                    onLoad={() => setLoaded(true)}
                    style={imgSx}
                  />
                </animated.div>
              );
            default:
              return null;
          }
        })}
      </Stack>
    );
  }
  const mobile = useMobileMode();

  return (
    <Stack>
      <div
          id="trio"
          style={{
            zIndex: 999,
            position: "fixed",
            width: "15px",
            height: "100vh",
            background:
              "linear-gradient(to bottom, #F7525B 10%, #1C2B4B 45%, #21A2B2 70%, #F7525B 100%)",
          }}
        ></div>
      <Stack
        width="100%"
        position="relative"
        overflow="hidden"
        sx={{
          paddingLeft: "var(--nav-safe-area-inset-left)",
          paddingBottom: "var(--nav-safe-area-inset-bottom)",
        }}
      >
        <Meta title={`About ${details.name.first}`} />
        <ThemeAwareIllustration />
        <Stack padding={mobile ? "1rem" : "5rem"} gap={2} alignItems="start">
          <Stack marginBottom="min(10vw, 3rem)" marginTop={mobile ? "10vw" : 0}>
            <Typography
              level="display1"
              fontSize="clamp(3rem, 15vw, 10rem)"
              fontWeight={200}
              lineHeight={1}
              sx={{
                position: "relative",
                marginLeft: "0.25em",
                marginBottom: "0.05em",
              }}
            >
              <Typography
                level="h2"
                sx={{
                  position: "absolute",
                  left: "-0.6em",
                  bottom: "1.7em",
                  transform: "translateX(-50%) rotate(-90deg)",
                  fontSize: "clamp(0.5rem, 3vw, 2rem)",
                }}
              ></Typography>
            </Typography>
            <Typography
              level="display1"
              fontSize="clamp(3rem, 15vw, 10rem)"
              fontWeight={300}
              lineHeight={0.6}
              zIndex={1}
              fontFamily={'"Righteous", sans-serif'}
            ></Typography>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            sx={
              mobile
                ? {
                    width: "100%",
                    "& > *:first-child": {
                      flex: 1,
                    },
                  }
                : {}
            }
          >
            <Button
              size="lg"
              component={Link}
              to="/"
              variant="outlined"
              color="neutral"
              endDecorator={<IoIosReturnLeft size="1.3em" />}
              sx={(theme) => ({
                marginTop: "13.8em",
                transition: "all ease .2s",
                position: "relative",
                borderRadius: "20px",
                boxShadow: "1px 1px grey",
                width: "fit-content",
                flexShrink: 0,
                padding: "1 2",
                background: theme.palette.background.body,
                "&:hover": {
                  // background: theme.palette.text.primary,
                  color: theme.palette.background.level1,
                  borderColor: theme.palette.text.primary,
                  "& > span > svg": {
                    transform: "translate(.6rem, .3rem) scale(1.2)",
                    filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.tertiary}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.secondary})`,
                  },
                },
                "&:active": {
                  transform: "scale(.98)",
                  "& > span > svg": {
                    transform: "translate(.6rem, .3rem) scale(1.1)",
                    filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1})`,
                  },
                },
                "& > span > svg": {
                  transition: "all ease .2s",
                },
              })}
            >
              Back to homepage
            </Button>
          </Stack>
          <Box mt={2}>
            <Typography
              level="h5"
              sx={{
                fontWeight: "bold",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Our Cultural Values
            </Typography>

            <Box
              component="form"
              sx={{
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
                marginTop: "20px",
                flexWrap: "wrap",
              }}
              gap={2}
            >
              {OurValues.map((value) => {
                return (
                  <Card
                    sx={(theme) => ({
                      fontWeight: "bold",
                      boxShadow: "5px 5px lightblue",
                      willChange: "filter",
                      transition: "all ease .2s",
                      position: "relative",
                      cursor: "pointer",
                      borderRadius: "1rem",
                      overflow: "hidden",
                      "&:hover, &.open, &.pressed": {
                        zIndex: 1,
                        borderColor: "#00AECF",
                        filter: `drop-shadow(0 .4rem 0 #00AECF)`,
                        transform: "translateY(-.4rem)",
                        "&:after": {
                          content: '""',
                          position: "absolute",
                          bottom: "-.4rem",
                          left: 0,
                          width: "100%",
                          height: ".4rem",
                          backgroundColor: "transparent",
                        },
                        "& .illustration": {
                          opacity: 0.8,
                        },

                        "& .icon": {
                          backgroundColor: theme.palette.text.primary,
                          color: "#00AECF",
                        },

                        "& + hr": {
                          display: "none",
                        },
                      },
                      "&.open": {
                        marginY: ".8rem",
                      },
                      "&.pressed": {
                        filter: `drop-shadow(0 .2rem 0 ${theme.palette.text.primary})`,
                        transform: "translateY(-.2rem)",
                      },
                    })}
                  >
                    <CardContent orientation="horizontal">
                      <img
                        src={value.imageURL} alt="Ourvaluesimages"
                        style={{ width: "240px", height: "120px" }}
                      />
                    </CardContent>
                    {value.name}
                  </Card>
                );
              })}
            </Box>
          </Box>

          <Box mt={2}>
            <Typography level="h5" sx={{ fontWeight: "bold" }}>
              Our Purpose
            </Typography>

            <Box
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                flexWrap: "wrap",
              }}
              gap={2}
            >
              <Card
                sx={(theme) => ({
                     
                  boxShadow: "3px 3px grey",
                  willChange: "filter",
                  transition: "all ease .2s",
                  position: "relative",
                  cursor: "pointer",
                  borderRadius: "1rem",
                  overflow: "hidden",
                  "&:hover, &.open, &.pressed": {
                    zIndex: 1,
                    // borderColor: "#00AECF",
                    filter: `drop-shadow(0 .3rem 0 #00AECF)`,
                    transform: "translateY(-.4rem)",
                    "&:after": {
                      content: '""',
                      position: "absolute",
                      bottom: "-.4rem",
                      left: 0,
                      width: "100%",
                      height: ".4rem",
                      backgroundColor: "transparent",
                    },
                    "& .illustration": {
                      opacity: 0.8,
                    },

                    "& .icon": {
                      backgroundColor: theme.palette.text.primary,
                      color: "#00AECF",
                    },

                    "& + hr": {
                      display: "none",
                    },
                  },
                  "&.open": {
                    marginY: ".8rem",
                  },
                  "&.pressed": {
                    filter: `drop-shadow(0 .2rem 0 ${theme.palette.text.primary})`,
                    transform: "translateY(-.2rem)",
                  },
                })}
              >
                <CardContent orientation="horizontal">
                  <Typography>
                    The purpose of Rapid Automation Group (RAG) is to delivers
                    advanced solutions to businesses in consumer and healthcare,
                    streamlining operations, boosting productivity, and cutting
                    costs. The team identified the rising demand for automation
                    and established a group to offer innovative, customize
                    solutions for each client’s unique needs. Their dedication
                    to innovation is clear through ongoing research and
                    development. RAG invests significantly in staying current
                    with the latest automation technologies, ensuring clients
                    receive the most advanced cost-effective solutions.
                  </Typography>
                </CardContent>
              </Card>
            </Box>

            <Typography mt={4} level="h5" sx={{ fontWeight: "bold" }}>
              Vision & Commitments
            </Typography>

            <Box
              component="form"
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                flexWrap: "wrap",
              }}
              gap={2}
            >
              <Card sx={(theme) => ({
                     
                      boxShadow: "3px 3px grey",
                      willChange: "filter",
                      transition: "all ease .2s",
                      position: "relative",
                      cursor: "pointer",
                      borderRadius: "1rem",
                      overflow: "hidden",
                      "&:hover, &.open, &.pressed": {
                        zIndex: 1,
                        // borderColor: "#00AECF",
                        filter: `drop-shadow(0 .3rem 0 #00AECF)`,
                        transform: "translateY(-.4rem)",
                        "&:after": {
                          content: '""',
                          position: "absolute",
                          bottom: "-.4rem",
                          left: 0,
                          width: "100%",
                          height: ".4rem",
                          backgroundColor: "transparent",
                        },
                        "& .illustration": {
                          opacity: 0.8,
                        },

                        "& .icon": {
                          backgroundColor: theme.palette.text.primary,
                          color: "#00AECF",
                        },

                        "& + hr": {
                          display: "none",
                        },
                      },
                      "&.open": {
                        marginY: ".8rem",
                      },
                      "&.pressed": {
                        filter: `drop-shadow(0 .2rem 0 ${theme.palette.text.primary})`,
                        transform: "translateY(-.2rem)",
                      },
                    })}>
                <CardContent orientation="horizontal">
                  <Typography>
                    <strong>Vision: </strong>High impact delivery, building
                    seamless integrated workflows, Unified intelligent automated
                    eco-system. Enhancing client experience & future-proofing,
                    empowering team with automation driven insights. Pioneering
                    sustainable automation practices cultivating a culture of
                    continuous improvement.<br></br>
                    <br></br>
                    <strong>Commitments: </strong>RAG's commitment to
                    innovation, research, and development, positions it as the
                    leader in transforming businesses through automation.
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Stack>
      </Stack>
      <Credits />
    </Stack>
  );
}
