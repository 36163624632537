/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from "react";
import { Stack, Typography, TypographyProps } from "@mui/joy";
import { SxProps, TextColor } from "@mui/joy/styles/types";
import Featured from "components/sections/Featured";
import Contact from "components/sections/contact/Contact";
import Terminal from "components/sections/Terminal";
import Meta from "components/Meta";
import Header from "components/sections/Header";
import { useMobileMode, Default, useLandScapeMode } from "components/Responsive";
import OpenSource from "components/sections/OpenSource";
import Divider from "components/sections/Divider";
import Goals from "components/sections/goals/Goals";
import { ParallaxProvider } from "react-scroll-parallax";
import Illustrations from "components/sections/Illustrations";
import Credits from "components/sections/Credits";
import details from "assets/Details";
import { leadersData, LeadersSlider } from "components/leaders/LeadersSpeak";


export function ATypography({
  children,
  href = "#",
  target = "_blank",
  textColor = "inherit",
  sx = {},
  ...props
}: {
  children: React.ReactNode;
  href?: string;
  target?: string;
  textColor?: TextColor;
  sx?: SxProps;
} & TypographyProps) {
  return (
    <Typography
      component="a"
      href={href}
      target={target}
      textColor={textColor}
      sx={{
        textDecoration: "dotted underline",
        "&:hover": {
          textDecoration: "underline",
        },
        ...sx,
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}

export default function Landing() {
  const mobile = useMobileMode();
  const landscape = useLandScapeMode();
  return (
    <ParallaxProvider>
      <div>
        <div
          id="trio"
          style={{
            zIndex: 999,
            position: "fixed",
            width: "15px",
            height: "100vh",
            background:
              "linear-gradient(to bottom, #F7525B 10%, #1C2B4B 45%, #21A2B2 70%, #F7525B 100%)",
          }}
        ></div>
        <Meta title={`${details.name.first}`} />
        <Stack overflow="hidden">
          <Illustrations />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              marginTop: `calc(-100vh + ${mobile ? "350px" : "140px"})`,
              width: "100vw",
              // paddingTop: "var(--nav-safe-area-inset-top)",
              paddingBottom: "var(--nav-safe-area-inset-bottom)",
              paddingLeft: "var(--nav-safe-area-inset-left)",
            }}
          >
            <Stack
              sx={{
                // width: "min(100%, 1200px)",
                width: "100%",
                height: "fit-content",
              }}
            >
              <Stack
                gap={mobile ? 3 : 0}
                sx={{
                  paddingLeft: mobile ? 0 : "70px",
                }}
              >
                <Stack mt={10} p="20px" gap="60px">
                  <Header />
                </Stack>
                <Stack mt={ mobile || !landscape ? -10 : -17} py="20px" gap="60px">
                  <Terminal />
                </Stack>
                <Stack
                  py="20px"
                  gap="80px"
                  sx={(theme) => ({
                    position: "relative",
                    // "&::before": mobile
                    //   ? {}
                    //   : {
                    //       content: '""',
                    //       position: "absolute",
                    //       top: "-30px",
                    //       left: "-30px",
                    //       height: "calc(100% + 20px)",
                    //       // width: "2px",
                    //       background: `linear-gradient(to bottom, ${theme.palette.info[500]} 10%, ${theme.palette.warning[500]} 70%)`,
                    //     },
                  })}
                >
                  <Featured />
                  <Contact />
                </Stack>
              </Stack>
              <Default>
                <Stack
                  sx={{
                    paddingX: mobile ? 0 : "70px",
                  }}
                >
                  {/* <Divider /> */}
                </Stack>
              </Default>
              <Stack
                sx={{
                  paddingRight: mobile ? 0 : "70px",
                }}
              >
                <Stack
                  p="20px"
                  gap="80px"
                  sx={(theme) => ({
                    position: "relative",
                    "&::before": mobile
                      ? {}
                      : {
                          content: '""',
                          position: "absolute",
                          top: "0",
                          right: "-30px",
                          height: "100%",
                          // width: "2px",
                          background: `linear-gradient(to bottom, ${theme.palette.success[500]} 30%, ${theme.palette.danger[500]})`,
                        },
                  })}
                >
                  <OpenSource />
                </Stack>
                <Stack
                  p="20px"
                  gap="80px"
                  sx={(theme) => ({
                    position: "relative",
                    "&::before": mobile
                      ? {}
                      : {
                          content: '""',
                          position: "absolute",
                          top: "0",
                          right: "-30px",
                          height: "100%",
                          // width: "2px",
                          background: `linear-gradient(to bottom, ${theme.palette.danger[500]}, transparent 60%)`,
                        },
                  })}
                >
                  <Goals />
                </Stack>
              </Stack>
            </Stack>
          </div>
          <LeadersSlider cardData={leadersData} />
          <Credits />
        </Stack>
      </div>
    </ParallaxProvider>
  );
}
