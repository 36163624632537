import {
  Box,
  Button,
  Stack,
  Typography,
  useColorScheme,
} from "@mui/joy";

import React, { useEffect, useState } from "react";
import Meta from "./Meta";
import { useMobileMode } from "./Responsive";
import details from "assets/Details";
import { Link } from "react-router-dom";
import { IoIosReturnLeft } from "react-icons/io";
// import { FaMinus, FaPlus } from "react-icons/fa";
// import Item from "./capabilities/Item";
// import { SkillButton } from "./capabilities/SkillButton";
// import { SiPowerbi, SiReact } from "react-icons/si";
import { animated, useSpringRef, useTransition } from "@react-spring/web";
import ProgressiveImage from "./ProgressiveImage";
// import ContactUS from "../assets/contact-us.jpg";
// import { GoLocation } from "react-icons/go";
// import { BsMailbox, BsPhone, BsPhoneFlip } from "react-icons/bs";
// import { MdEmail } from "react-icons/md";
import OrgChart from "./organization/OrgChart";
import Credits from "./sections/Credits";
import TeamWorkOrgChart from "../assets/icons/team-work-org-chart.png";

export default function Organization() {
  function ThemeAwareIllustration() {
    const { colorScheme } = useColorScheme();
    const [loaded, setLoaded] = useState(false);

    const transRef = useSpringRef();

    const transitions = useTransition(colorScheme, {
      ref: transRef,
      keys: null,
      from: { opacity: 0 },
      enter: { opacity: 1 },
      leave: { opacity: 0, filter: "blur(10px)", position: "absolute" },
    });

    useEffect(() => {
      if (loaded) {
        transRef.start();
      }
    }, );
    // [colorScheme, loaded]
    const imgSx = {
      position: "relative",
      marginLeft: "30em",
      marginTop: "-5rem",
      width: "70%",
      // WebkitMaskImage:
      //   "linear-gradient(to left,white 0.10%,transparent 99.99%)",
      maskImage: "linear-gradient(to left, white 50%,transparent 99.99%)",
    } as React.CSSProperties;

    return (
      <Stack
        sx={{
          position: "absolute",
          right: "min(0rem, calc(100vw - 100rem))",
          width: "100rem",
          height: "min(100%, 27rem)",
          overflow: "hidden",
          paddingTop: "var(--nav-safe-area-inset-top)",
          "&:after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            // mask: "linear-gradient(to left, transparent, #ffffffa9 99.99%)",
          },
        }}
      >
        {transitions((style, item) => {
          switch (item) {
            case "light":
              return (
                <animated.div style={style}>
                  <ProgressiveImage
                    src={TeamWorkOrgChart}
                    placeholder={TeamWorkOrgChart}
                    alt="Brutalist building by Arthur Swiffen"
                    onLoad={() => setLoaded(true)}
                    style={imgSx}
                  />
                </animated.div>
              );
            default:
              return null;
          }
        })}
      </Stack>
    );
  }

  const mobile = useMobileMode();
  return (
    <Stack>
      <div
          id="trio"
          style={{
            zIndex: 999,
            position: "fixed",
            width: "15px",
            height: "100vh",
            background:
              "linear-gradient(to bottom, #F7525B 10%, #1C2B4B 45%, #21A2B2 70%, #F7525B 100%)",
          }}
        ></div>
      <Stack
        width="100%"
        position="relative"
        overflow="hidden"
        sx={{
          paddingLeft: "var(--nav-safe-area-inset-left)",
          paddingBottom: "var(--nav-safe-area-inset-bottom)",
        }}
      >
        <Meta title={`Contact ${details.name.first}`} />
        <ThemeAwareIllustration />
        <Stack padding={mobile ? "1rem" : "5rem"} gap={2} alignItems="start">
          <Stack marginBottom="min(10vw, 3rem)" marginTop={mobile ? "10vw" : 0}>
            <Typography
              level="display1"
              fontSize="clamp(3rem, 15vw, 10rem)"
              fontWeight={200}
              lineHeight={1}
              sx={{
                position: "relative",
                // marginLeft: "0.25em",
                marginBottom: ".05em",
              }}
            >
              <Typography
                level="h2"
                sx={{
                  position: "absolute",
                  left: "-.6em",
                  bottom: "1.7em",
                  transform: "translateX(-50%) rotate(-90deg)",
                  fontSize: "clamp(.5rem, 3vw, 2rem)",
                }}
              >
                {/* Check out our */}
              </Typography>
              Organization
            </Typography>
            <Typography
              level="display1"
              fontSize="clamp(3rem, 15vw, 10rem)"
              fontWeight={300}
              lineHeight={0.6}
              zIndex={1}
              fontFamily={'"Righteous", sans-serif'}
            >
              Structure
            </Typography>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            sx={
              mobile
                ? {
                    width: "100%",
                    "& > *:first-child": {
                      flex: 1,
                    },
                  }
                : {}
            }
          >
            <Button
              size="lg"
              component={Link}
              to="/"
              variant="outlined"
              color="neutral"
              endDecorator={<IoIosReturnLeft size="1.3em" />}
              sx={(theme) => ({
                marginTop: "-1em",
                transition: "all ease .2s",
                position: "relative",
                borderRadius: "20px",
                boxShadow: "1px 1px grey",
                width: "fit-content",
                flexShrink: 0,
                padding: "1 2",
                background: theme.palette.background.body,

                "&:hover": {
                  // background: theme.palette.text.primary,
                  color: theme.palette.background.level1,
                  borderColor: theme.palette.text.primary,
                  "& > span > svg": {
                    transform: "translate(.6rem, .3rem) scale(1.2)",
                    filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.tertiary}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.text.secondary})`,
                  },
                },
                "&:active": {
                  transform: "scale(.98)",
                  "& > span > svg": {
                    transform: "translate(.6rem, .3rem) scale(1.1)",
                    filter: `drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1}) drop-shadow(-.3rem -.3rem 0 ${theme.palette.background.level1})`,
                  },
                },
                "& > span > svg": {
                  transition: "all ease .2s",
                },
              })}
            >
              Back to homepage
            </Button>
          </Stack>
        </Stack>
        <Box sx={{ color: "white" }} mt={-7.5}>
          <OrgChart />
        </Box>
      </Stack>
      <Credits />
    </Stack>
  );
}
